<template>
  <div id="centTitle">
    <div>{{ title }}</div>
    <date-picker></date-picker>
  </div>
</template>

<script>
import datePicker from "./datePicker.vue";
export default {
  components: { datePicker },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
#centTitle {
  position: relative;
  font-size: var(--subSize);
  color: var(--subPink);
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    border-bottom: 1px solid #dbdbdb;
    box-sizing: border-box;
    z-index: 1;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 3rem;
    border-bottom: 2px solid #ef420e;
    box-sizing: border-box;
    z-index: 2;
  }
}
</style>