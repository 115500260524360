<template>
  <el-date-picker
    v-model="dateTime"
    :type="type"
    :format="format"
    :value-format="valueFormat"
    :placeholder="placeholder"
    :picker-options="{ firstDayOfWeek: 1, disabledDate }"
    @change="dateInfo"
    v-show="isShow"
  >
  </el-date-picker>
</template>

<script>
export default {
  data() {
    return {
      // 限制选择日期
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      isShow: false, // 是否显示组件
      dateTime: "", // 日期
      type: "", // 时间属性
      format: "", // 显示在输入框中的日期格式
      valueFormat: "", // 输出的日期格式
      placeholder: "", // 占位符
    };
  },
  created() {
    let name = this.$route.name;
    let date = new Date();
    let nowDate = this.getDateTime(date.getTime()); // 当前日期
    if (name === "daily") {
      this.isShow = true;
      this.dateTime = nowDate;
      this.type = "date";
      this.format = "yyyy - MM - dd";
      this.valueFormat = "yyyy/MM/dd";
      this.placeholder = "请选择日期";

      let dateInfo = this.getDateTime(date.getTime() - 24 * 60 * 60 * 1000);
      this.getDateInfo(dateInfo);
    } else if (name === "weekly") {
      this.isShow = true;
      this.dateTime = nowDate;
      this.type = "week";
      this.format = "yyyy - MM - dd";
      this.valueFormat = "yyyy/MM/dd";
      this.placeholder = "请选择周";
      let dateInfo = {
        starDay: this.getDateTime(
          date.getTime() - (date.getDay() + 6) * (24 * 60 * 60 * 1000)
        ),
        endDay: this.getDateTime(
          date.getTime() - date.getDay() * (24 * 60 * 60 * 1000)
        ),
      };
      this.getDateInfo(dateInfo);
    } else if (name === "monthly") {
      let y = date.getFullYear(); // 年份
      let m = date.getMonth() + 1; // 月份

      this.isShow = true;
      this.dateTime = y + "/" + m;
      this.type = "month";
      this.format = "yyyy - MM";
      this.valueFormat = "yyyy/MM";
      this.placeholder = "请选择月份";

      let nowY = m - 1 > 0 ? y : y - 1;
      let nowM = m - 1 > 0 ? m - 1 : 12;
      let dateInfo = nowY + "/" + nowM + "/" + "1";
      this.getDateInfo(dateInfo);
    }
  },
  methods: {
    // 用户勾选的日期
    dateInfo(e) {
      let date = new Date().getTime();
      let da = new Date(e).getTime();
      let name = this.$route.name;
      if (name === "daily") {
        if (date - da < 24 * 60 * 60 * 1000) {
          this.$message.error("不能选择当前日期！");
        } else {
          this.getDateInfo(e);
        }
      } else if (name === "weekly") {
        if (da + 24 * 60 * 60 * 1000 * 5 > date) {
          this.$message.error("不能选择当前日期！");
        } else {
          let dateInfo = {
            endDay: this.getDateTime(da - 24 * 60 * 60 * 1000),
            startDay: this.getDateTime(da + 24 * 60 * 60 * 1000 * 5),
          };
          this.getDateInfo(dateInfo);
        }
      } else if (name === "monthly") {
        if (date - da < 30 * 24 * 60 * 60 * 1000) {
          this.$message.error("不能选择当前日期！");
        } else {
          this.getDateInfo(e + "/" + "1");
        }
      }
    },
    // 日期
    getDateInfo(e) {
      let that = this;
      setTimeout(function () {
        that.$bus.$emit("dataDate", e);
      }, 10);
    },
    getDateTime(time) {
      let date = new Date(time);
      let year = date.getFullYear(); // 年份
      let month = date.getMonth() + 1; // 月份
      let day = date.getDate(); // 日期
      return year + "/" + "0" + month + "/" + day;
    },
  },
};
</script>

<style>
</style>